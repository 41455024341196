
.process-3dep1, 
.process-3dep2{
    position: relative;
}

.process-3dep1::after, 
.process-3dep2::after{
    content: "";
    display: block;
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 4px solid #f4f7fb;
    background-color: #e4eefc;
    background-image: url(/images/sub/products/wmds/process-arrow.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    top: 50%;
    right: -72px;
    transform: translate(0, -50%);
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .card{
        min-height: 246px;
    }
}
@media (min-width:1200px){
}

    /* Title */
    .tit-txt-wrap{
        margin-bottom: 40px;
    }
    .tit-wrap{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }
    .tit-txt-wrap .tit-wrap{
        margin-bottom: 10px;
    }
    .tit{
        width: 100%;
        font-size: 2.6rem;
        font-weight: 500;
        line-height: 1.2;
        word-break: keep-all;
    }
    .tit.font-notoserif{
        line-height: 1.4;
    }
    .tit--xs{
        font-size: 1.8rem;
        font-weight: 600;
    }
    .tit--sm{
        font-size: 2rem;
    }
    .tit--lg{
        font-size: 3.2rem;
        line-height: 40px;
        font-weight: 700;
        margin-bottom: 38px;
    }
    .tit-txt{
        font-size: 1.4rem;
    }


    @media (min-width:576px){
    }
    @media (min-width:768px){
        .tit{
            font-size: 3rem;
        }
        .tit--xs{
            font-size: 1.8rem;
        }
        .tit--lg{
            font-size: 4rem;
            line-height: 50px;
        }
    }
    @media (min-width:1024px){
        .tit-txt-wrap{
            margin-bottom: 60px;
        }
        .tit-wrap{
            margin-bottom: 60px;
        }
        .tit-txt-wrap .tit-wrap{
            margin-bottom: 20px;
        }
        .tit{
            font-size: 3.6rem;
        }
        .tit--xs{
            font-size: 2rem;
        }
        .tit--sm{
            font-size: 2.4rem;
        }
        .tit--lg{
            font-size: 5.2rem;
            line-height: 66px;
            margin-bottom: 72px
        }
        .tit-txt{
            font-size: 1.6rem;
        }
    }
    @media (min-width:1200px){
    }
/* Page Text */
.page-text{
    font-size: 1.6rem;
    line-height: 24px;
}
.page-text-sm{
    font-size: 1.4rem;
    line-height: 20px;
}
.page-text-lg{
    font-size: 1.6rem;
    line-height: 24px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .page-text{
        font-size: 2rem;
        line-height: 30px;
    }
    .page-text-sm{
        font-size: 1.8rem;
        line-height: 28px;
    }
    .page-text-lg{
        font-size: 2.2rem;
        line-height: 30px;
    }
}
@media (min-width:1200px){
}
